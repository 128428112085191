/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//TODO:
require('./src/styles/global.scss');

exports.shouldUpdateScroll = () => {
  if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
    return false;
  }
  window.scrollTo(0, 0);

  return false;
};
